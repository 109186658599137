<template>
    <v-container
        style="max-width: 95%;"
    >
        <v-row class="justify-center" style="display: grid;">
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <a href="/">
                    <v-img :src="require('@/assets/images/logo.png')" max-height="300" max-width="300" />
                </a>
            </v-col>

        </v-row>

        <v-row>
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-stepper 
                    alt-labels
                    style="box-shadow: none;"
                >
                    <v-layout align-center justify-center>
                        <v-row style="display: contents !important;">
                            <v-col>
                                <v-stepper-header
                                    style="box-shadow: none; "
                                >
                                    <v-stepper-step
                                        :complete="getStatusID(pedidoCotacaoResponse.status) >= 1"
                                        style="width: 200px;"
                                        step="1"
                                    >
                                        <div style="width: 200px !important; text-align: center;">{{ getStatusDescricao(1) }}</div>
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :complete="getStatusID(pedidoCotacaoResponse.status) >= 3"
                                        style="width: 300px;"
                                        step="2"
                                    >
                                        <div style="width: 300px !important; text-align: center;">{{ getStatusDescricao(3) }}</div>
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :complete="getStatusID(pedidoCotacaoResponse.status) >= 4"
                                        style="width: 200px;"
                                        step="3"
                                    >
                                        <div style="width: 200px !important; text-align: center;">{{ pedidoCotacaoResponse.descricaoStatusCliente }}</div>
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step
                                        :complete="getStatusID(pedidoCotacaoResponse.status) == 8"
                                        style="width: 200px;"
                                        step="3"
                                    >
                                        <div style="width: 200px !important; text-align: center;">{{ getStatusDescricao(8) }}</div>
                                    </v-stepper-step>

                                </v-stepper-header>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-stepper>

            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                lg="7"
                md="6"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                >
                                    {{ tipo == 'P' ? 'Dados do Pedido' : 'Dados da Cotação' }} 
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="6"
                                    md="6"
                                    sm="12"
                                    style="text-align: right;"
                                >
                                    <v-chip
                                        color="var(--color__main)"
                                        outlined
                                        dark
                                    >
                                        {{ pedidoCotacaoResponse.descricaoStatusCliente }}
                                    </v-chip>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px; padding: 4px;">
                                        <tr>
                                            <td class="header">ID</td>
                                            <td> {{ pedidoCotacaoResponse.idFormatted }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Número Cotação</td>
                                            <td v-if="habilitaLinkCotacao">
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <a v-bind:href="pedidoCotacaoResponse.customerUrlCotacao" v-on="on" target="_blank">
                                                            {{ pedidoCotacaoResponse.numeroCotacao }}
                                                        </a>
                                                    </template>
                                                    <span>Link Cotação</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-else> 
                                                {{ pedidoCotacaoResponse.numeroCotacao }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="header">Criado em</td>
                                            <td> {{ pedidoCotacaoResponse.dataCadastroFormatted }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Tipo Cliente</td>
                                            <td> {{ pedidoCotacaoResponse.tipoClienteDescricao }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Condição de Pagamento</td>
                                            <td> {{ pedidoCotacaoResponse.condicaoPagamentoSelected.descricao }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Forma de Pagamento</td>
                                            <td> {{ pedidoCotacaoResponse.formaPagamentoSelected.descricao }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Marca</td>
                                            <td> {{ pedidoCotacaoResponse.marcaDescricao }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Tipo de Frete</td>
                                            <td> {{ pedidoCotacaoResponse.tipoFreteSelected.descricao }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Número do Pedido</td>
                                            <td v-if="habilitaLinkPedido">
                                                <v-tooltip right>
                                                    <template v-slot:activator="{ on }">
                                                        <a v-bind:href="pedidoCotacaoResponse.customerUrlPedido" v-on="on" target="_blank">
                                                            {{ pedidoCotacaoResponse.codigoPedidoTotvs }}
                                                        </a>
                                                    </template>
                                                    <span>Link Pedido</span>
                                                </v-tooltip>
                                            </td>
                                            <td v-else> 
                                                {{ pedidoCotacaoResponse.codigoPedidoTotvs }}
                                            </td>
                                        </tr>
                                        <tr v-if="pedidoCotacaoResponse.transportadoraSelected != null && pedidoCotacaoResponse.transportadoraSelected != undefined">
                                            <td class="header">Transportadora</td>
                                            <td> {{ pedidoCotacaoResponse.transportadoraSelected.name }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
            <v-col
                cols="12"
                lg="5"
                md="6"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    Dados do Cliente
                                </v-col>
                            </v-row>                            
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr>
                                            <td class="header">Código</td>
                                            <td> {{ pedidoCotacaoResponse.clienteSelected.id }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Cnpj</td>
                                            <td> {{ pedidoCotacaoResponse.clienteSelected.cnpjProcessed }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Nome</td>
                                            <td> {{ pedidoCotacaoResponse.clienteSelected.name }}</td>
                                        </tr>
                                        <tr>
                                            <td class="header">Email</td>
                                            <td> {{ pedidoCotacaoResponse.clienteSelected.email }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>

                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-text class="text-h6" style="border-bottom: solid 1px var(--color__cinza)">
                            <v-row
                                v-if="isPedidoTurbo"
                            >
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-icon large style="color: var(--color__main);">mdi-flash</v-icon> 
                                    <label style="font-size: 20px; color: var(--color__main); font-style: italic;">ENTREGA TURBO *</label>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col 
                                    cols="6"
                                    lg="6"
                                    md="6"
                                    sm="6"
                                >
                                    <label style="font-size: 0.875rem;">Frete</label>
                                </v-col>
                                <v-col 
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <label class="valorTotal"> R$ {{ pedidoCotacaoResponse.valorFreteFormatted }}</label>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col 
                                    cols="6"
                                    lg="6"
                                    md="6"
                                    sm="6"
                                    class="header"
                                >
                                    <label style="font-size: 0.875rem;">Total</label>
                                </v-col>
                                <v-col 
                                    cols="6"
                                    style="text-align: right;"
                                >
                                    <label class="valorTotal"> R$ {{ totalImpostos.totalComIPI }}</label>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Itens
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-simple-table
                                        fixed-header
                                        style="cursor: pointer;"
                                    >
                                        <template v-slot:default>
                                            <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Item
                                                    </th>
                                                    <th class="text-left">
                                                        Codigo
                                                    </th>
                                                    <th class="text-left" style="width: 40%;">
                                                        Descrição
                                                    </th>
                                                    <th class="text-center">
                                                        Data/Prazo Entrega
                                                    </th>
                                                    <th class="text-center">
                                                        NCM
                                                    </th>
                                                    <th class="text-right">
                                                        ICMS
                                                    </th>
                                                    <th class="text-right">
                                                        IPI
                                                    </th>
                                                    <th class="text-right">
                                                        Qtde
                                                    </th>
                                                    <th class="text-right" v-if="existeItemCertificacaoEX">
                                                        Valor Cert. EX
                                                    </th>
                                                    <th class="text-right">
                                                        Valor Unitário
                                                    </th>
                                                    <th class="text-right" v-if="isPedidoTurbo">
                                                        Valor Turbo
                                                    </th>
                                                    <th class="text-right">
                                                        Valor Total
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(item, index) in pedidoCotacaoResponse.listItems" :key="index"
                                                    style="cursor: pointer;"
                                                    title="Clique para mais detalhes"
                                                    @click="preencherDetalhes(item)"
                                                >
                                                    <td>{{ item.item }}</td>
                                                    <td>{{ item.codigoProduto }}</td>
                                                    <td>{{ item.descricaoProduto }}</td>
                                                    <td class="text-center"><div v-html="getDataEntrega(item)"></div></td>
                                                    <td class="text-center">{{ item.ncm }}</td>
                                                    <td class="text-right">{{ item.aliqICMSFormatted }} %</td>
                                                    <td class="text-right">{{ item.aliqIPIFormatted }} %</td>
                                                    <td class="text-right">{{ item.qtde }}</td>
                                                    <td class="text-right" v-if="item.certificacaoEX == 1">{{ item.valorCertificacaoEXFormatted }}</td>
                                                    <td class="text-right">{{ getValorUnitario(item).valorUnitario }}</td>
                                                    <td class="text-right" v-if="isPedidoTurbo">{{ getValorUnitario(item).valorTurbo }}</td>
                                                    <td class="text-right">{{ valorTotalCalculado(item).valorTotalUnitarioFormatado }}</td>
                                                    <td v-if="item.keyAccount == 1">
                                                        <v-tooltip 
                                                            left
                                                        >
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon 
                                                                    v-on="on"
                                                                    right 
                                                                    color="green"
                                                                >
                                                                    mdi-account-key-outline
                                                                </v-icon>
                                                            </template>
                                                            <span>Key Account</span>
                                                        </v-tooltip>                                                                
                                                    </td>
                                                </tr>
                                                <tr v-if="showDetails && itemDetail != null">
                                                    <td colspan="12" style="margin: 0px; padding: 0px;">
                                                        <table style="width: 80%; border: none; padding: 20px;" cellspacing="10">
                                                            <tr>
                                                                <td colspan="2">
                                                                    <label style="color: var(--color__main);">Descontos Aplicados</label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="columnDetail">
                                                                    <label>Desconto de Qtde</label>
                                                                </td>
                                                                <td>
                                                                    <label> {{ itemDetail.descontoQtdeFormatted }} % </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="columnDetail">
                                                                    <label>Ouro / Prata</label>
                                                                </td>
                                                                <td>
                                                                    <label> {{ itemDetail.descontoCampanhaOuroPrataFormatted }} % </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="columnDetail">
                                                                    <label>Campanha de Crédito</label>
                                                                </td>
                                                                <td>
                                                                    <label> {{ itemDetail.descontoCampanhaCreditoFormatted }} % </label>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td class="columnDetail">
                                                                    <label>Campanha de Estoque</label>
                                                                </td>
                                                                <td>
                                                                    <label> {{ itemDetail.descontoCampanhaEstoqueFormatted }} % </label>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td :colspan="getColspan()" class="text-right"><strong>Total com ICMS, PIS e COFINS (SEM IPI)</strong></td>
                                                    <td class="text-right"><strong>R$ {{ totalImpostos.totalSemIPI }}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td :colspan="getColspan()" class="text-right"><strong>Total com IPI</strong></td>
                                                    <td class="text-right"><strong>R$ {{ totalImpostos.totalComIPI }}</strong></td>
                                                </tr>
                                            </tfoot>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                            </v-row> 
                            <v-row
                                v-if="isPedidoTurbo"
                            >
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label style="font-size: 12px; font-weight: normal !important; color: #64BE49; font-style: italic;">* Sujeito à confirmação do Customer Service após o envio do pedido</label>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="tipo == 'P'"
        >
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Mensagem da Nota
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr>
                                            <td>{{ pedidoCotacaoResponse.mensagemNota }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>

        <v-row
            v-if="tipo == 'P'"
        >
            <v-col
                cols="12"
                lg="12"
                md="12"
                sm="12"
                style="padding: 0px !important;"
            >
                <v-card-text>

                    <v-card
                        class="mx-auto"
                    >
                        <v-card-title class="text-h6 lighten-2" style="border-bottom: solid 1px var(--color__cinza)">
                            Observações
                        </v-card-title>
                        <v-card-text>

                            <v-row>
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <table style="width: 100%; border: none; margin-top: 10px;">
                                        <tr>
                                            <td>{{ pedidoCotacaoResponse.observacao }}</td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row> 
                        </v-card-text>

                    </v-card>
                    
                </v-card-text>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import { formaterDecimalBR } from '@/utilities/Utils';
    import { 
        CONSULTAR_FABRICA_DESCRICAO,
    } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        data: () => ({

            pedidoCotacaoResponse: {
                id: 0,
                idFormatted: "#000000",
                cnpjCliente: "",
                nome: "",
                valorFrete: "0,00",
                observacao: "",
                clienteSelected: {
                    id: "",
                    name: '',
                    email: ''
                },
                condicaoPagamentoSelected: {
                    id: 0,
                    descricao: ''
                },
                formaPagamentoSelected: {
                    id: 0,
                    descricao: ''
                },
                tipoFreteSelected: {
                    id: 0,
                    descricao: ''
                },
            },
            og: Math.pow(10, 2),

            loading: false,
            showDetails: false,
            itemDetail: null,

            moneyFormat: {
                suffix: '',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },

            consultarFabricaDescricao: CONSULTAR_FABRICA_DESCRICAO,

        }),

        computed: {
            totalImpostos() {
                let totalGeral = 0;
                let aliqIPI = 0;
                let valorTotalIPI = 0;

                if (this.pedidoCotacaoResponse != null && this.pedidoCotacaoResponse != undefined && this.pedidoCotacaoResponse.listItems != null && this.pedidoCotacaoResponse.listItems != undefined) {
                    this.pedidoCotacaoResponse.listItems.forEach(item => {

                        let valorUnitarioTotal = this.valorTotalCalculado(item).valorTotalUnitario;

                        totalGeral += valorUnitarioTotal;
                        aliqIPI = parseFloat(item.aliqIPI) / 100;
                        valorTotalIPI += (valorUnitarioTotal * aliqIPI);
                    })
                }

                let totalSemIPI = Math.floor( (totalGeral * this.og) ) / this.og;
                let totalComIPI = Math.floor( ((totalGeral + valorTotalIPI) * this.og) ) / this.og;
                
                return {
                    totalSemIPI: formaterDecimalBR(totalSemIPI),
                    totalComIPI: formaterDecimalBR(totalComIPI)
                }
            },

            pedidoIntegrado() {
                if (this.pedidoCotacaoResponse.codigoPedidoTotvs == null || 
                    this.pedidoCotacaoResponse.codigoPedidoTotvs == undefined || 
                    this.pedidoCotacaoResponse.codigoPedidoTotvs.toString().trim() == '') {
                    return false;
                }
                else {
                    return true;
                }
            },

            existeItemCertificacaoEX() {
                return this.pedidoCotacaoResponse.listItems != null &&
                       this.pedidoCotacaoResponse.listItems != undefined &&
                       this.pedidoCotacaoResponse.listItems.filter(item => item.certificacaoEX == 1).length > 0;
            },

            isTipoDistribuidor() {
                return this.pedidoCotacaoResponse.tipoCliente != null && 
                       this.pedidoCotacaoResponse.tipoCliente != undefined && 
                       this.pedidoCotacaoResponse.tipoCliente.id == 2
            },

            isPedidoTurbo() {
                return this.pedidoCotacaoResponse.entregaTurbo == 1
            },

            habilitaLinkCotacao() {
                return this.tipo == "P" && 
                       this.pedidoCotacaoResponse != null &&
                       this.pedidoCotacaoResponse != undefined &&
                       this.pedidoCotacaoResponse.guidCotacao != '';
            },

            habilitaLinkPedido() {
                return this.tipo == "O" && 
                       this.pedidoCotacaoResponse != null &&
                       this.pedidoCotacaoResponse != undefined &&
                       this.pedidoCotacaoResponse.guidPedido != '';
            }
        },

        props: {
            guid: {
                default: ""
            },

            tipo: {
                default: ""
            }
        },

        methods: {

            valorTotalCalculado(item) {
                const qtde = parseFloat(item.qtde);
                const valorUnitario = parseFloat(item.precoBruto) + item.valorCertificacaoEX;
                const valorTotalUnitario = Math.floor( ((valorUnitario * qtde) * this.og) ) / this.og;
                return {
                    valorTotalUnitario,
                    valorTotalUnitarioFormatado: formaterDecimalBR(valorTotalUnitario)
                }
            },

            getValorUnitario(item) {

                let valorUnitario = 0;
                let valorTurbo = 0;

                if (this.isPedidoTurbo == false) {
                    valorUnitario = item.precoBrutoFormatted;
                }
                else {
                    const valorSemTurbo = parseFloat(item.precoBruto) / 1.5;
                    valorUnitario = formaterDecimalBR ( Math.floor( ((valorSemTurbo) * this.og) ) / this.og );

                    const somenteValorTurbo = ( parseFloat(item.precoBruto) - valorSemTurbo ) * item.qtde;
                    valorTurbo = formaterDecimalBR ( Math.floor( ((somenteValorTurbo) * this.og) ) / this.og );
                }

                return {
                    valorUnitario,
                    valorTurbo
                }
            },

            getColspan() {
                let colSpan = 9;

                if (this.existeItemCertificacaoEX == true) {
                    colSpan += 1;
                }

                if (this.isPedidoTurbo) {
                    colSpan += 1;
                }

                return colSpan;
            },

            getDataEntrega(item) {
                const uteisCorridos = this.tipo == "P" ? "corridos" : "úteis";
                const consultarFabrica = item.prazoEntrega == 0 ? this.consultarFabricaDescricao : null;
                let dataPrazoEntrega = `${item.prazoEntrega} dias ${uteisCorridos}`;

                if (consultarFabrica != null) {
                    dataPrazoEntrega = consultarFabrica;
                }
                else if (this.pedidoIntegrado == true) {
                    dataPrazoEntrega += `<br />${item.dataEntregaTotvs}`;
                }
                else if (item.entregaProgramada == 1) {
                    dataPrazoEntrega += `<br />${item.dataEntregaFormatted}`;
                }
                
                return dataPrazoEntrega;
            },

            getStatusID(status) {

                switch (status) {

                    case 1:
                    case 2:
                        return 1;

                    case 3:
                    case 5:
                    case 6:
                        return 3;

                    default:
                        return status;
                }
            },

            getStatusDescricao (status) {

                let descricao = "";

                switch (status) {

                    case 1: //Aguardando Aprovação
                        descricao = "Aguardando Aprovação";
                        break;
                        
                    case 3: //Aprovado e Aguardando Processamento
                        descricao = "Aprovado e Aguardando Processamento";
                        break;

                    case 4: //Em produção
                        descricao = "Em Produção";
                        break;

                    case 8: //Finalizado
                        descricao = "Finalizado";
                        break;
                
                    default:
                        break;
                }

                return descricao
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = null;
                    
                    if (this.tipo == "P") {
                        response = await this.$store.dispatch("pedidoModule/GetByGuid", this.guid);
                    }
                    else {
                        response = await this.$store.dispatch("orcamentoModule/GetByGuid", this.guid);
                    }                    

                    if (response.success) {
                        this.pedidoCotacaoResponse = response.result;

                        if (this.tipo == "P") {
                            this.pedidoCotacaoResponse.listItems = this.pedidoCotacaoResponse.listItemsPedido;
                        }
                        else {                            
                            this.pedidoCotacaoResponse.listItems = this.pedidoCotacaoResponse.listItemsOrcamento;
                        }
                    }
                }
            },

            async preencherDetalhes(item) {

                if ( this.itemDetail == null || (this.itemDetail != null && this.itemDetail.id == item.id)) {
                    this.showDetails = !this.showDetails;
                }

                this.itemDetail = item;
            }
        },

        async created() {
            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .header {
        font-weight: bold;
        width: 30%;
    }

    .valorTotal {
        color: var(--color__main) !important;
        font-weight: bold;
        font-size: 20px;
    }

    td {
        padding: 3px;
    }

    @media only screen and (max-width: 647px) {
        .header {
            width: 50%;
        }
    }

</style>

<style>

    .v-stepper__step__step {
        height: 50px !important;
        width: 50px !important;
        font-size: 15px;
    }

    .v-stepper--alt-labels .v-stepper__header .v-divider {
        margin-top: 50px !important;
    }
</style>